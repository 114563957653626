import React, { useEffect, useState } from "react";
import tonConnectUI, { getBalance, formatAddress, connectWallet } from "./TonConnectHandler";

const Footer = () => {
  const [wallet, setWallet] = useState(null);
  const [address, setAddress] = useState("Не подключен");
  const [balance, setBalance] = useState("--");

  useEffect(() => {
    // Подписываемся на изменения статуса кошелька
    tonConnectUI.onStatusChange(async (newWallet) => {
      if (newWallet) {
        const formattedAddress = formatAddress(newWallet.account.address);
        const walletBalance = await getBalance(newWallet.account.address);
        setWallet(newWallet);
        setAddress(formattedAddress);
        setBalance(walletBalance || "--");
      } else {
        setWallet(null);
        setAddress("Не подключен");
        setBalance("--");
      }
    });
  }, []);

  return (
    <footer>
      <div id="wallet-info">
        <p id="wallet-address">Кошелек: {address}</p>
        <p id="wallet-balance">Баланс: {balance} TON</p>
      </div>
      <button
        id="connect-button"
        className="button"
        onClick={() => connectWallet(wallet)}
      >
        {wallet ? "Отключить кошелек" : "Подключить кошелек"}
      </button>
    </footer>
  );
};

export default Footer;
