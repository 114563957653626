import React, { useEffect, useState } from "react";

const Header = () => {

  return (
    <header className="header">
      <h1>#1 MOST TRUSTED PLACE TO FLIP</h1>
      <div id="coin-img-container">
        <img src="./img/coin.png" alt="Coin" id="coin-img" />
      </div>
    </header>
  );
};

export default Header;
