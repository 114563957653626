import React, { useState, useEffect } from "react";
import tonConnectUI, { initTonConnect, connectWallet } from "./TonConnectHandler";
import Header from "./Header";
import GameSection from "./GameSection";
import Footer from "./Footer";
import "./App.css";

function App() {
  const [wallet, setWallet] = useState(null);
  const [balance, setBalance] = useState("--");
  const [address, setAddress] = useState("Не подключен");

  useEffect(() => {
    initTonConnect((newWallet) => {
      if (newWallet) {
        setWallet(newWallet);
        setAddress(newWallet.account.address);
        setBalance(newWallet.account.balance || "--");
      } else {
        setWallet(null);
        setAddress("Не подключен");
        setBalance("--");
      }
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <GameSection wallet={wallet} tonConnectUI={tonConnectUI} />
      <Footer
        wallet={wallet}
        balance={balance}
        address={address}
        connectWallet={() => connectWallet(wallet)}
      />
    </div>
  );
}

export default App;
