const TonWeb = require('tonweb');

const sendTransaction = async (wallet, toAddress, amount, payload) => {
    try {
        // Преобразуем сумму в нанотоны
        const nanoAmount = TonWeb.utils.toNano(amount);

        // Получаем seqno
        const seqno = await wallet.methods.seqno().call();

        console.log("Seqno:", seqno);

        // Оцениваем комиссию перед отправкой
        const fee = await wallet.methods.transfer({
            secretKey: wallet.secretKey, // Убедитесь, что секретный ключ доступен
            toAddress,
            amount: nanoAmount,
            seqno,
            payload,
            sendMode: 3,
        }).estimateFee();

        console.log("Оценка комиссии:", fee);

        // Отправляем транзакцию
        await wallet.methods.transfer({
            secretKey: wallet.secretKey,
            toAddress,
            amount: nanoAmount,
            seqno,
            payload,
            sendMode: 3,
        }).send();

        console.log("Транзакция успешно отправлена!");
        return { success: true, fee };
    } catch (error) {
        console.error("Ошибка при отправке транзакции:", error);
        throw error;
    }
};

export default sendTransaction;
