import { TonConnectUI } from "@tonconnect/ui";

const tonConnectUI = new TonConnectUI({
  manifestUrl: "https://tonflip.com/tonconnect-manifest.json",
});

export const initTonConnect = (onStatusChange) => {
  tonConnectUI.onStatusChange(onStatusChange);
};

export const getBalance = async (address) => {
  try {
    const response = await fetch("https://toncenter.com/api/v2/jsonRPC", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "getAddressInformation",
        params: { address },
        api_key:
          "5bd8c6ffd153913903a63106ed49d9bf32879fa96f42660cfc7d6516f8d8c685",
      }),
    });
    const data = await response.json();
    return (parseFloat(data.result.balance) / 10 ** 9).toFixed(2);
  } catch (error) {
    console.error("Ошибка получения баланса:", error);
    return "Ошибка";
  }
};

export const connectWallet = (wallet) => {
  if (wallet) {
    tonConnectUI.disconnect();
  } else {
    tonConnectUI.openModal();
  }
};

// Функция форматирования адреса (добавлено)
export const formatAddress = (address) => {
  if (!address) return "Неизвестный адрес";
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

export default tonConnectUI;
